<template>
  <div>
    <div v-if="!loaded || !countries" class="card card-custom">
      <div class="card-body">
        <b-overlay :show="true" opacity="0.3">
          <div v-if="!loaded" class="min-vh-60 d-flex flex-column align-items-center justify-content-center">
            <div class="text-center mb-20">
              <h4>{{ $t("Loading assignment data...") }}</h4>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
    <secondment-create-wizard v-else :resume="resumeWizard" :countries="countries"
      :missing-travel-plan="missingTravelPlan"></secondment-create-wizard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState, mapActions } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";
import SecondmentService from "@/core/services/secondment/secondment.service";

export default {
  components: {
    SecondmentCreateWizard: () => import( /* webpackChunkName: "edit-SecondmentCreateWizard" */ "@/view/components/wizards/secondment-create-wizard/SecondmentCreateWizard.vue"),
  },

  beforeRouteEnter(_, from, next) {
    next((vm) => {
      vm.resumeWizard = false;
      if (from.query.pending) vm.resumeWizard = true;
    });
  },

  data() {
    return {
      resumeWizard: false,
      loaded: false,
      missingTravelPlan: false,
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    secondmentId() {
      return this.$route.params.secondmentId;
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments") },
      { title: this.$t("Edit") },
    ]);
    this.$route.query.mode = 'edit'
    await this.init();
  },

  methods: {
    ...mapActions("wizard", ["setEdit"]),
    async init() {
      await SecondmentService.getOne({ id: this.secondmentId }).then(response => {
        let secondment = response.data;

        if (secondment?.travel_plans) {
          secondment.travel_plans = secondment.travel_plans.map(tp => {
            tp.travel_days = tp.travel_days.map(td => {
              td.places = td.places.sort((a, b) => a.order - b.order);
              return td;
            });
            tp.employees = secondment.employees.filter(emp => tp.employees.includes(emp.id))
            return tp;
          });
          if (secondment.travel_plans.length === 0) {
            this.missingTravelPlan = true;
            if (secondment.employees) {
              secondment.employees = secondment.employees.map(e => {
                if (!e.places) {
                  e.places = [
                    {
                      place_kind: "arrival",
                      place: e.places?.find(el => el.place_kind === "arrival"),
                    },
                    {
                      place_kind: "accommodation",
                      place: e.places?.find(el => el.place_kind === "accommodation"),
                    },
                    {
                      place_kind: "departure",
                      place: e.places?.find(el => el.place_kind === "departure"),
                    },
                  ];
                }
                return e;
              });
            }
          }
        }

        return secondment;
      }).then(secondment => {
        return this.setEdit(secondment);
      }).then(() => {
        this.loaded = true;
      }).catch(err => {
        console.log(err)
        // Nel caso in cui non sia autorizzato l'errore è comunque 404
        if (err.response.status === 404) {
          this.$router.push({ name: '404' }); //unauthorized
          return
        }
        backendErrorSwal(err, err?.response?.data?.detail);
      });
    },
  }
};
</script>
<style scoped>
.min-vh-60 {
  min-height: 60vh;
}
</style>
