var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.loaded || !_vm.countries ? _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('b-overlay', {
    attrs: {
      "show": true,
      "opacity": "0.3"
    }
  }, [!_vm.loaded ? _c('div', {
    staticClass: "min-vh-60 d-flex flex-column align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "text-center mb-20"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("Loading assignment data...")))])])]) : _vm._e()])], 1)]) : _c('secondment-create-wizard', {
    attrs: {
      "resume": _vm.resumeWizard,
      "countries": _vm.countries,
      "missing-travel-plan": _vm.missingTravelPlan
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }